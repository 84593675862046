<template>
  <div v-if="!willRedirectToLogin" role="main">
    <Component
      :is="`blueprint-${entry.blueprint?.replaceAll('_', '-')}`"
      v-if="entry"
      :page="entry"
    />
    <CmsButton v-if="entry?.edit_url" :edit-url="entry.edit_url" />
    <div v-if="previewEnabled && entry" class="hidden">
      <!-- force reactivity until we find a better solution -->
      {{ entry }}
    </div>
  </div>
</template>

<script setup>
import { useStatamicPreviewMode } from '~/composables/useStatamicPreviewMode'
const route = useRoute()
const localePath = useLocalePath()
const { locale, locales } = useI18n()
const router = useRouter()
const alternates = useState('alternates', () => [])
const payload = ref(null)
const { user } = useAuthStore()
const willRedirectToLogin = ref(false)
const { enabled: previewEnabled } = useStatamicPreviewMode()

definePageMeta({
  middleware: ['login'],
})

const localeRegex = new RegExp(
  `/(${locales.value.map((l) => l.code).join('|')})(?=/|$)`
)

// remove locale prefix from current route path
let uri = route.path.replace(localeRegex, '')
// remove trailing slash
uri = uri.replace(/\/$/, '')
// set uri to root if empty
uri = uri === '' ? '/' : uri

// if uri starts with /protected, use protected query
const isProtected = uri.startsWith('/protected')
const password = useCookie(uri)

payload.value = await useCachedGql({
  operation: isProtected ? 'EntryProtected' : 'Entry',
  variables: {
    uri,
    lang: (isProtected && route.query.lang) || locale.value,
    status: previewEnabled.value
      ? '(draft|expired|scheduled|published)'
      : 'published',
    password: password,
  },
})

// If the page is protected and there is an error, redirect to login
if (isProtected && payload.value.error) {
  redirect('/protected/login', uri)
} else if (!payload.value?.data?.entry) {
  throw createError({
    fatal: true,
    statusCode: 404,
  })
}

// There is a protected switch (*not the same as protected pages from above*) on most of the pages. If it is set to true and the user is not logged in, redirect to login
if (!user?.id && payload.value?.data?.entry.protected) {
  willRedirectToLogin.value = true
}

provide('pageId', payload.value?.data?.entry.id)
provide('breadcrumbs', payload.value?.data?.entry.breadcrumbs)

// set page alternate links
alternates.value = Array.isArray(payload.value?.data?.entry?.alternates)
  ? payload.value?.data?.entry.alternates
  : []

// META TAGS
await useHead({
  ...useGenerateHead(payload.value?.data?.entry, locale.value),
})

// COMPUTED
const entry = computed(() => payload.value?.data?.entry || {})

// FUNCTIONS
async function redirect(to, params) {
  // we need to wait for the page to prevent insert node error
  await new Promise((resolve) => setTimeout(resolve, 300))

  router.push({
    path: localePath(to),
    query: {
      returnUrl: params,
      lang: locale.value,
    },
  })
}

onMounted(async () => {
  // track page views form flyer links (Laura Wyss requested it)
  if (route.query.qr !== undefined) {
    if (locale.value === 'fr') {
      fathom.trackEvent('Page opened with flyer link FR')
    } else {
      fathom.trackEvent('Page opened with flyer link DE')
    }
  }

  if (willRedirectToLogin.value) {
    // we need to wait for the page to prevent insert node error
    await new Promise((resolve) => setTimeout(resolve, 300))

    // if still not logged in, redirect to login
    if (!user?.id) {
      router.push(
        localePath({
          path: localePath('/login'),
          query: {
            returnUrl: localePath(uri),
          },
        })
      )
    } else {
      willRedirectToLogin.value = false
    }
  }
})
</script>
